@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./theme/scrollbar.css');

@font-face {
	font-family: 'Inter';
	src: url(./assets/fonts/Inter.ttf);
}

#root {
	position: fixed;
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.chakra-offset-slide {
	height: 100%;
}

@layer utilities {
  .striped {
    background: repeating-linear-gradient(
      45deg,
      #f1f5f9 0px,
      #f1f5f9 15px,
      white 15px,
      white 30px
    );
  }
}
